<template>
  <div>
    <Card>
      <template #card-body>
        <b-row class="mt-2">
          <b-col md="4">
            <label class="text-muted">Filtro</label>
            <b-form-select
              v-model="filterSelected"
              :options="[
                { value: '1', text: 'Ativo' },
                { value: '0', text: 'Inativo' },
              ]"
              @change="$emit('search', { status: $event })"
            >
              <b-form-select-option :value="'all'">Todos</b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-table class="mt-3" hover :fields="tableSchema" :items="leads">
          <template #cell(id)="data">
            <b-dropdown
              class="p-0 m-0 d-flex"
              size="md"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <fa-icon size="1x" icon="bars" />
              </template>
              <b-dropdown-item @click="show_detail(data.item)" title="Detalhes"
                >Detalhes</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status == 'Recebido'"
              pill
              variant="warning"
              >Recebido</b-badge
            >
            <b-badge
              v-if="data.item.status == 'Confirmado'"
              pill
              variant="success"
              >Confirmado</b-badge
            >
          </template>
        </b-table>
      </template>
    </Card>
    <SweetModal
      ref="lead_detail"
      overlay-theme="dark"
      hide-close-button
      width="70%"
    >
      <template #title>
        <span class="text-muted"> Detalhes </span>
      </template>
      <template v-if="model !== ''">
        <b-row
          class="mt-4"
          v-for="(schema, index_row) in confirmDetailsSchema"
          :key="`confirm_details_${index_row}`"
        >
          <CreateForm
            v-for="(item, index_item) in schema"
            :key="`confirm_details_input${index_item}`"
            :schema="item"
            v-model="model[item.model]"            
          />
        </b-row>
      </template>
      <template #button>
        <div>
          <b-button class="mr-2" variant="primary-custom" @click="$refs.lead_detail.close()">Fechar</b-button>
          <b-button variant="primary-custom" @click="submit_confirm">Confirmar</b-button>
        </div>
      </template>
    </SweetModal>
  </div>
</template>

<script>
import tableSchema from "../schemas/tableLeadsSchema";
import confirmDetailsSchema from "../schemas/confirmDetailsSchema";
export default {
  props: ["leads"],
  data() {
    return {
      filterSelected: "all",
      tableSchema,
      confirmDetailsSchema,      
      model:''
    };
  },
  methods: {
    async submit_confirm(){
      const response = await this.$store.dispatch('leads/save_confirm_lead', {
        id:this.model.id,
        status: '3'
      })
      if(response){
        this.$refs.lead_detail.close()
      }
    },
    show_detail(data) {      
      this.model = data.file
      this.model.id = data.id
      this.$refs.lead_detail.open();
    },
  },
};
</script>

<style>
</style>